import * as React from "react";
import Obfuscate from 'react-obfuscate';

import Layout from "../components/layout";
import Seo from "../components/seo";

import "./contact.css";

const ContactPage = () => {
  return(
    <Layout>
      <Seo title="Contact" />
      <h2>Let's work together</h2>
      <p>Based in, but not bounded by Toronto. Feel free to get in touch if you have a project you'd like to work on.</p>
      <div className="contact-container">
        <div className="contact-button">
          <Obfuscate
            email="nicholayiannakis@gmail.com"
            className="contact-button-text"
          >
            Email
          </Obfuscate>
        </div>
        <a className="contact-button" href="https://www.linkedin.com/in/nicholas-yiannakis" target="_blank" rel="noreferrer">
          LinkedIn
        </a>
        {/* <a className="contact-button" href="https://vimeo.com/nickyiannakis" target="_blank" rel="noreferrer">
          Vimeo
        </a> */}
        {/* <a className="contact-button" href="https://www.instagram.com/create.flow/" target="_blank" rel="noreferrer">
          Instagram
        </a> */}
      </div>
    </Layout>
  );
};

export default ContactPage;
